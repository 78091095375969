
import { ConfiguredProduct, ProductFlight, FlightConfig } from '@/shared/types';
import Vue, { PropType } from 'vue';
import CreativePreview from '@/entities/instantIO/ui/templates/CreativePreview.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Models, Services } from '@/injectables/tokens';
import { CreativeModelContract, CreativeType, DateModelContract, ValidationServiceContract } from '@/injectables';
import CreativeCarouselImage from '@/entities/creative/ui/creative-carousel-image.vue';

export default Vue.extend({
  name: 'Creative',

  useInjectable: [Models.Date, Models.Creatives, Services.Validation],

  props: {
    product: {
      type: Object as PropType<ConfiguredProduct>,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    productName: {
      type: String,
      default: 'Product',
    },
    creativeProp: {
      type: Object,
      required: true,
    },
    creativeNumber: {
      type: Number,
      default: 1,
    },
    toggleDisabled: {
      type: Boolean,
      default: false,
    },
    flights: {
      type: Array,
      default: () => [],
    },
    formConfig: {
      type: Array,
      default: () => [],
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: { CreativeCarouselImage, CreativePreview, WrapperWithTooltip },

  data: (): {
    creativeFormValid: boolean;
  } => ({
    creativeFormValid: false,
  }),

  methods: {
    flightDates(flight: ProductFlight): string[] {
      const [start = 'n/a', end = 'n/a'] = [flight.startDate, flight.endDate];
      return [start, end].map((this[Models.Date] as DateModelContract).dateToFormatDateString);
    },
    creativeHasProperty(prop: string, label?: string): boolean {
      return this.formConfig.some(
        c => c.PropertyId === prop && (!label || c.label === label || c.label === `${label}*`),
      );
    },
    toggleExpansion(): void {
      if (this.toggleDisabled) return;
      this.$emit('toggle-expansion');
    },
    validLink(link: string): string {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`;
    },
    deleteCreative(): void {
      this.$emit('delete-creative');
    },
    updateCreativeProperty(value: string | number, property: string): void {
      if (this?.[property]) {
        this[property] = value;
      }
    },
    addCarouselItem(): void {
      this.$emit('add-carousel-item');
    },
    updateImageItem(creative, index: number): void {
      const val = this.creativeProp.creatives.map(el => el);
      val.splice(index, 1, { ...creative });
      this.$emit('update-creative', {
        prop: 'creatives',
        val,
      });
    },
    deleteImageItem(index: number): void {
      const val = this.creativeProp.creatives.map(el => el);
      val.splice(index, 1);
      this.$emit('update-creative', {
        prop: 'creatives',
        val,
      });
    },
  },

  computed: {
    canAddNewCarouselItem(): boolean {
      return (this.creativeProp?.creatives?.length || 0) < 10;
    },
    showCarousel(): boolean {
      return (
        (this[Models.Creatives] as CreativeModelContract).getCreativeType(this.creativeProp) === CreativeType.Carousel
      );
    },
    saveDisabled(): boolean {
      return !this.creativeFormValid;
    },
    rules() {
      const validationService: ValidationServiceContract = this.validationService;
      return {
        postTextMaxLength: validationService.maxLengthValidatorFactory(125),
        headlineMaxLength: validationService.maxLengthValidatorFactory(40),
        descriptionMaxLength: validationService.maxLengthValidatorFactory(30),
      };
    },
    productHasFlights(): boolean {
      return Array.isArray(this.flights) && this.flights.length > 0;
    },
    parsedFlights() {
      return this.flights;
    },
    flightsSelection: {
      get(): string[] {
        return (
          Array.isArray(this.creativeProp?.flightIds) ? this.creativeProp?.flightIds : [this.creativeProp?.flightIds]
        ).filter(Boolean);
      },
      set(val: string[]) {
        const value = (Array.isArray(val) ? val : [val]).filter(Boolean);
        this.$emit('update-creative', { prop: 'flightIds', val: value });
      },
    },
    adTypes() {
      const flightId = this.flightsSelection?.[0];
      if (!flightId) return [];
      const flight = this.product?.flights?.find((flight: ProductFlight) => flight.id === flightId);
      if (!flight) return [];
      const flightConfig = this.product?.flightConfigs?.find(
        (flightConfig: FlightConfig) => flightConfig.targetingOption === flight.targetingOption,
      );
      return flightConfig?.adFormatList || [];
    },
    adType: {
      get(): number {
        return Array.isArray(this.creativeProp?.selectedAdFormatList)
          ? this.creativeProp?.selectedAdFormatList[0]
          : this.creativeProp?.selectedAdFormatList;
      },
      set(val: number[]) {
        const value = Array.isArray(val) ? val : [val];
        this.$emit('update-creative', { prop: 'selectedAdFormatList', val: value });
      },
    },
    disableDelete(): boolean {
      const isLocalCreative = !this.creativeProp?.index;
      const isEmptyCreative =
        !Object.keys(this.creativeProp).length || Object.keys(this.creativeProp).every(key => !this.creativeProp[key]);
      return (
        this.isChangeDisabled ||
        (isLocalCreative && isEmptyCreative) ||
        this.$store.state.newProposal.updateCreativeLoading
      );
    },
    url: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'url');
        const label = foundFormConfig?.label || 'Landing Page';
        const value = this.creativeProp?.url || '';
        return { label, value };
      },
      set(link: string): void {
        this.$emit('update-creative', { prop: 'url', val: link });
      },
    },
    notes: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'notes');
        const label = foundFormConfig?.label || 'Notes';
        const value = this.creativeProp?.notes || '';
        return { label, value };
      },
      set(notes: string): void {
        this.$emit('update-creative', { prop: 'notes', val: notes });
      },
    },
    postText: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'postText');
        const label = foundFormConfig?.label || 'Post Text';
        const value = this.creativeProp?.postText || '';
        return { label, value };
      },
      set(postText: string): void {
        this.$emit('update-creative', { prop: 'postText', val: postText });
      },
    },
    headline: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'headline');
        const label = foundFormConfig?.label || 'Headline';
        const value = this.creativeProp?.headline || '';
        return { label, value };
      },
      set(headline: string): void {
        this.$emit('update-creative', { prop: 'headline', val: headline });
      },
    },
    description: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'description');
        const label = foundFormConfig?.label || 'Description';
        const value = this.creativeProp?.description || '';
        return { label, value };
      },
      set(description: string): void {
        this.$emit('update-creative', { prop: 'description', val: description });
      },
    },
    socialLink: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'socialLink');
        const label = foundFormConfig?.label || 'Social Page URL';
        const value = this.creativeProp?.socialLink || '';
        return { label, value };
      },
      set(link: string): void {
        this.$emit('update-creative', { prop: 'socialLink', val: link });
      },
    },
    creativeLink: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'creativeLink');
        const label = foundFormConfig?.label || 'Media';
        const value = this.creativeProp?.creativeLink || '';
        return { label, value };
      },
      set(link: string): void {
        this.$emit('update-creative', { prop: 'creativeLink', val: link });
      },
    },
    customMargin: {
      get(): { label: string; value: number } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'customMargin');
        const label = foundFormConfig?.label || 'Custom Margin';
        const value = this.creativeProp?.customMargin || 0;
        return { label, value };
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set(percent: any): void {
        // is there a max percent?
        if (!isNaN(percent)) {
          const parsed = parseFloat(percent);
          this.$emit('update-creative', { prop: 'customMargin', val: parsed });
        }
      },
    },
  },
});
