
import Vue from 'vue';

import { Models, Services } from '@/injectables/tokens';

// Conversion Tracker
import ConversionTracker from '@/widgets/instant-io/ui/conversion-tracker/conversion-tracker.vue';
import { ConversionTracking } from '@/shared/types';
import { ProposalProductModelContract } from '@/injectables';

export default Vue.extend({
  name: 'ProposalActionCard',

  useInjectable: [Services.Upload, Services.Contract, Models.Files, Models.ProposalProduct],

  components: { ConversionTracker },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      trackerValidStatus: true,
      wasChanged: false,
    };
  },

  computed: {
    canSetPixelRequest(): boolean {
      // TODO: rename or create new flag
      return this.$store.state.agency?.currentAgencyInfo?.canSetPixelRequestForInstantCampaign;
    },
    pixelRequest: {
      get(): boolean {
        return this.$store.state.newProposal.newProposal.pixelRequest;
      },
      set(value: boolean) {
        this.wasChanged = true;
        return this.$store.dispatch('newProposal/setProposalActions', { pixelRequest: value });
      },
    },
    goLiveWitoutRetargetingValue: {
      get(): boolean {
        return this.$store.state.newProposal.newProposal.goLiveWitoutRetargeting;
      },
      set(value: boolean) {
        this.wasChanged = true;
        return this.$store.dispatch('newProposal/setProposalActions', { goLiveWitoutRetargeting: value });
      },
    },
    conversionTracking(): ConversionTracking {
      return this.$store.state.newProposal.newProposal.conversionTracking;
    },
    trackVisitsValue: {
      get(): boolean {
        return this.conversionTracking?.visits?.trackVisits;
      },
      set(value: boolean) {
        this.setTracking({
          ...this.conversionTracking,
          visits: { ...this.conversionTracking?.visits, trackVisits: value },
        });
      },
    },
    websiteValue: {
      get(): string {
        return this.conversionTracking?.visits?.website;
      },
      set(value: string) {
        this.setTracking({
          ...this.conversionTracking,
          visits: { ...this.conversionTracking?.visits, website: value },
        });
      },
    },
    trackConversionValue: {
      get(): boolean {
        return this.conversionTracking?.conversion?.trackConversion;
      },
      set(value: boolean) {
        this.setTracking({
          ...this.conversionTracking,
          conversion: { ...this.conversionTracking?.conversion, trackConversion: value },
        });
      },
    },
    websitesValue: {
      get(): string[] {
        return this.conversionTracking?.conversion?.websites;
      },
      set(value: string[]) {
        this.setTracking({
          ...this.conversionTracking,
          conversion: { ...this.conversionTracking?.conversion, websites: value },
        });
      },
    },
    validTracker() {
      if (!this.trackVisitsValue && !this.trackConversionValue) return true;
      return this.trackerValidStatus;
    },
    isLoading(): boolean {
      return (
        this.$store.state.newProposal.inProgressLoading ||
        this.$store.state.newProposal.saveProgressLoading ||
        this.loading
      );
    },
    products() {
      return this.$store.state.newProposal.newProposal.products;
    },
    canConfigureTracker() {
      return (
        this.canSetConversionTracking &&
        this.products.some(pr => (this[Models.ProposalProduct] as ProposalProductModelContract).isTrackableProduct(pr))
      );
    },
    canSetConversionTracking() {
      return this.$store.state.agency?.currentAgencyInfo?.canSetConversionTracking;
    },
  },

  methods: {
    setTracking(value) {
      this.wasChanged = true;
      this.$store.dispatch('newProposal/setProposalActions', { conversionTracking: value });
    },
    async saveActions() {
      if (!this.trackerValidStatus) return;
      const result = await this.$store.dispatch('newProposal/saveProposalActions');
      if (result) this.wasChanged = false;
    },
  },
});
