
import Vue from 'vue';

export default Vue.extend({
  name: 'CreativeListItem',

  props: {
    variant: {
      type: String,
      default: 'simple',
      validator: (variant: string) => ['simple', 'nested'].includes(variant),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    icon() {
      return this.variant === 'nested' ? 'expand_more' : 'chevron_right';
    },
  },
});
