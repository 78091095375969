
import { ConfiguredCreative, ConfiguredProduct, CreativeConfig, FormConfig } from '@/shared/types';
import Vue from 'vue';

import Creative from './Creative.vue';
import Questionnaire from './Questionnaire.vue';
import { CreativeListItem } from '@/entities/creative';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { BaseProductModelContract, CreativeModelContract, ProposalProductModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import { ProductConfigCategory } from '@/app/graphql';
import { ProductConfigType } from '../../../app/graphql/_types';

export default Vue.extend({
  name: 'CreativesCard',

  useInjectable: [Models.BaseProduct, Models.ProposalProduct, Models.Creatives],

  components: { Creative, CreativeListItem, WrapperWithTooltip, Questionnaire },

  props: {
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    activeProduct: ConfiguredProduct | null;
    activeProductPackageId: string | null;
    expandedIndex: number;
    expanded: boolean;
  } => ({
    activeProduct: null,
    expandedIndex: 0,
    expanded: true,
    activeProductPackageId: null,
  }),

  watch: {
    activeProduct(val: ConfiguredProduct): void {
      const creatives = val?.creatives || [];
      const indexes = creatives.map(c => c.id);
      if (this.expandedIndex && creatives.some(el => el.id === this.expandedIndex)) return;
      if (indexes.includes(this.expandedIndex)) {
        return;
      } else {
        this.expandedIndex = creatives[0]?.id;
      }
    },
  },

  methods: {
    getReadableCategory(category) {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(category);
    },
    addCreativeCarouselItem(creative) {
      const currentCreative = creative;

      const newCreative = (this[Models.ProposalProduct] as ProposalProductModelContract).getNewCreativeChild(
        currentCreative,
      );
      const creatives = [...(currentCreative?.creatives || []), newCreative];

      this.updateCreative({ prop: 'creatives', val: creatives }, currentCreative);
    },
    updateCreative({ prop, val }: { prop: string; val: string }, creative): void {
      const updatedCreative = {
        ...creative,
        unsaved: true,
        [prop]: val,
      };

      if (['flightIds', 'selectedAdFormatList'].includes(prop)) updatedCreative.creatives = [];

      const productCreatives = [...this.activeProductCreatives].map(c => {
        if (c.id === updatedCreative.id) return updatedCreative;
        return c;
      });

      this.$store
        .dispatch('newProposal/updateCreatives', {
          productId: this.activeProduct.id,
          creatives: productCreatives,
        })
        .then(() => {
          const updatedProduct = this.flatProducts.find(p => p.id === this.activeProduct.id);
          this.activeProduct = updatedProduct || null;
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('CreativesCard/updateCreative (local)', err);
        });
    },
    async revertUnsavedCreative(): Promise<void> {
      const { proposalId } = this.$route.params;
      await this.$store.dispatch('newProposal/getProposalCreatives', {
        proposalId,
      });

      this.activeProduct = this.flatProducts.find(p => p.id === this.activeProduct.id) || null;
    },
    saveCreative(): void {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { flights, creatives: children, ...creativeWithoutChildren } = this.currentCreative;
      this.$store
        .dispatch('newProposal/sendUpdatedCreative', {
          productId: this.activeProduct.id,
          creative: { ...creativeWithoutChildren, children: children || [] },
          packagePropertyId: this.activeProductPackageId,
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('CreativesCard/saveCreative', err);
        });
    },
    deleteCreative(creative): void {
      this.$store
        .dispatch('newProposal/removeCreative', {
          creativeId: creative.id,
          productId: this.activeProduct.id,
        })
        .then(product => {
          if (!product) return;

          this.activeProduct = { ...this.activeProduct, ...product };
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('CreativesCard/deleteCreative', err);
        });
    },
    createNewCreative(): void {
      const configs = (this.activeProductCreativeFormConfig || []) as FormConfig[];

      if (configs.length) {
        const newCreative = {} as { flightIds: string[] } & { [key: string]: string | number };
        configs.forEach(({ DataType, PropertyId }) => {
          if (PropertyId === 'flightIds') {
            newCreative[PropertyId] = [];
          } else {
            newCreative[PropertyId] = DataType === 'string' ? '' : 0;
          }
        });

        const productHasOnlyOneFlight = this.activeProduct?.flights?.length === 1;
        const productHasOnlyOneCreative = this.activeProduct?.creatives?.length === 0;

        if (productHasOnlyOneCreative) {
          newCreative.flightIds = this.activeProduct?.flights?.map(flight => flight.id);
        } else if (productHasOnlyOneFlight) {
          newCreative.flightIds = [this.activeProduct?.flights[0]?.id];
        }

        const index =
          this.activeProduct.creatives.length === 0
            ? 1
            : Math.max(...this.activeProduct.creatives?.map(({ index }) => index || 0)) + 1;

        this.$store
          .dispatch('newProposal/createNewCreative', {
            productId: this.activeProduct.id,
            creative: {
              ...newCreative,
              index,
              ...(productHasOnlyOneFlight ? { flightIds: this.activeProduct?.flights[0]?.id } : {}),
            },
            packagePropertyId: this.activeProductPackageId,
          })
          .then(product => {
            this.activeProduct = { ...this.activeProduct, ...product };
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('CreativesCard/createNewCreative', err);
          });
      }
    },
    createNewQuestionnaire(): void {
      const productConfigs = this.$store.state.newProposal.productConfigs?.list || [];
      const productType = this.activeProduct?.type;
      const searchConfig = productConfigs.find(p => p.type === productType);
      const questions = searchConfig?.questions;
      const emptyQuestionnaire = questions.map(q => {
        return {
          ...q,
          question: {
            text: q.text,
          },
          answer: '',
          questionnaireId: q.id,
        };
      });
      this.activeProduct = {
        ...this.activeProduct,
        questionnaire: emptyQuestionnaire.sort((a, b) => a?.index - b?.index),
      };
    },
    removeQuestionnaire(): void {
      const products = this.selectedProducts;
      const productType = this.activeProduct?.type;
      const productIndex = this.selectedProducts.findIndex(p => p.type === productType);

      if (productIndex === -1) {
        for (const product of this.selectedProducts) {
          if (product.products?.length) {
            const productIndex = product.products.findIndex(p => p.type === productType);
            let productToUpdate = this.activeProduct;

            const questionnaireInput = [];
            productToUpdate = { ...productToUpdate, questionnaire: questionnaireInput };
            product.products.splice(productIndex, 1, productToUpdate);
          }
        }
      } else {
        let productToUpdate = this.selectedProducts[productIndex];

        const questionnaireInput = [];
        productToUpdate = { ...productToUpdate, questionnaire: questionnaireInput };
        products.splice(productIndex, 1, productToUpdate);
      }
      this.$store.dispatch('newProposal/updateProducts', { products });
      this.$emit('refetch-proposal');
    },
    updateQuestion(value: string, index: number): void {
      const questionnaire = this.activeProduct.questionnaire;
      questionnaire?.sort((a, b) => a?.index - b?.index);
      let questionToUpdate = questionnaire[index];

      questionToUpdate = { ...questionToUpdate, answer: value };

      this.activeProduct.questionnaire.splice(index, 1, questionToUpdate);
    },
    async saveQuestionnaire(): Promise<void> {
      const products = this.selectedProducts;
      const productType = this.activeProduct?.type;

      const productIndex = this.selectedProducts.findIndex(p => p.type === productType);

      if (productIndex === -1) {
        for (const product of this.selectedProducts) {
          if (product.products?.length) {
            const productIndex = product.products.findIndex(p => p.type === productType);
            let productToUpdate = this.activeProduct;

            const questionnaireInput = productToUpdate.questionnaire.map(q => {
              return {
                index: q.index,
                questionnaireId: q.questionnaireId,
                answer: q.answer,
              };
            });
            productToUpdate = { ...productToUpdate, questionnaire: questionnaireInput };
            product.products.splice(productIndex, 1, productToUpdate);
          }
        }
      } else {
        let productToUpdate = this.selectedProducts[productIndex];

        const questionnaireInput = this.activeProduct.questionnaire.map(q => {
          return {
            index: q.index,
            questionnaireId: q.questionnaireId,
            answer: q.answer,
          };
        });
        productToUpdate = { ...productToUpdate, questionnaire: questionnaireInput };
        products.splice(productIndex, 1, productToUpdate);
      }

      await this.$store.dispatch('newProposal/updateProducts', { products });

      this.$emit('refetch-proposal');
    },
    isExpanded(id): boolean {
      return this.expandedIndex === id && this.expanded;
    },
    toggleExpansion(id): void {
      if (this.expandedIndex !== id) {
        this.expandedIndex = id;
        this.expanded = true;
      } else {
        this.expanded = !this.expanded;
      }
    },
    productIcon(categoryName): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(categoryName).icon;
    },
    isActiveProduct(product, index?: number): boolean {
      if (!this.activeProduct?.id && index === 0) {
        this.setActiveProduct(product);
        return true;
      }

      return this.activeProduct?.id === product.id;
    },
    setActiveProduct(product, packageId?: string): void {
      this.activeProductPackageId = packageId ?? null;
      this.activeProduct = product || null;
    },
    productHasCreative(product): boolean {
      const productCreativeConfig = this.creativeConfigs.find(c => c?.id === product.id);

      const questionnaire = product?.questionnaire;

      const findEmptyValue = questionnaire?.find(q => !q.answer);

      return (
        parseInt(productCreativeConfig?.creativesLimit) === 0 ||
        product?.creatives?.length > 0 ||
        (questionnaire?.length && !findEmptyValue)
      );
    },
    isPackage(category) {
      return ProductConfigCategory.Package === category;
    },
    checkIfCreativesConfigured(product): boolean {
      return product.creatives.every(c =>
        (this[Models.Creatives] as CreativeModelContract).creativeConfigured(product, c),
      );
    },
  },

  computed: {
    creativeConfigured(): boolean {
      return (this[Models.Creatives] as CreativeModelContract).creativeConfigured(
        this.activeProduct,
        this.currentCreative,
      );
    },
    saveQuestionnaireDisabled(): boolean {
      const questionnaire = this.activeProduct?.questionnaire;

      const findEmptyValue = questionnaire.find(q => !q.answer);

      return !!findEmptyValue;
    },
    currentCreative() {
      return this?.activeProduct?.creatives?.find(creative => creative.id === this.expandedIndex);
    },
    saveProductAttachmentsTitle(): string {
      return this.activeProductCanHaveQuestionnaire ? 'Save Questionnaire' : 'Save updated creative';
    },
    addProductAttachmentsTitle(): string {
      return this.activeProductCanHaveQuestionnaire ? 'Begin Questionnaire' : 'Add creative';
    },
    activeProductCanHaveQuestionnaire(): boolean {
      const questionnaireProducts = [ProductConfigType.GoogleSearch, ProductConfigType.EmailMarketing];
      return questionnaireProducts.includes(this.activeProduct?.type);
    },
    loading(): boolean {
      return this.$store.state.newProposal.updateCreativeLoading;
    },
    disabledToggle(): boolean {
      const currentCreative = this.currentCreative;
      return !this.isChangeDisabled && currentCreative?.unsaved;
    },
    hasUnsavedChanges(): boolean {
      const activeCreative = this.currentCreative;
      return !this.isChangeDisabled && activeCreative?.unsaved;
    },
    activeProductCreatives(): ConfiguredCreative[] {
      return this.activeProduct?.creatives ?? [];
    },
    activeProductQuestionnaire() {
      const productConfigs = this.$store.state.newProposal.productConfigs?.list || [];
      const productType = this.activeProduct?.type;
      const searchConfig = productConfigs.find(p => p.type === productType);
      const questions = searchConfig?.questions;
      const answers = this.activeProduct?.questionnaire;

      if (!(answers && answers.length)) {
        return [];
      }

      answers?.sort((a, b) => a?.index - b?.index);
      questions?.sort((a, b) => a?.index - b?.index);

      const questionnaireWithAnswers = questions?.map((q, i) => {
        return {
          ...q,
          question: {
            required: q?.required,
            text: q?.text,
          },
          answer: answers?.[i].answer || '',
          questionnaireId: q?.id,
        };
      });

      return questionnaireWithAnswers ?? [];
    },
    activeProductCreativeFormConfig(): FormConfig[] {
      if (this.activeProduct?.id) {
        return (this[Models.Creatives] as CreativeModelContract).getCreativeConfig(this.activeProduct).FormConfig;
      }

      return [];
    },
    canAddNewCreative(): boolean {
      if (this.isChangeDisabled || !this.activeProduct?.id || !this.activeProductCreativeFormConfig?.length) {
        return false;
      }

      return true;
    },
    allSelectedProducts(): ConfiguredProduct[] {
      return this.$store.state.newProposal.newProposal?.products || [];
    },
    selectedProducts(): ConfiguredProduct[] {
      return this.$store.state.newProposal.newProposal?.products.filter(p => p?.budget && p?.name) || [];
    },
    flatProducts(): ConfiguredProduct[] {
      return this.selectedProducts.map(p => (this.isPackage(p.category) ? p.products : p)).flat();
    },
    creativeConfigs(): CreativeConfig[] {
      return this.$store.state.newProposal.creativeConfigs.list; // empty array if loaded: false
    },
  },
  beforeDestroy() {
    this.expandedIndex === null;
  },
});
