
import { ProposalContract } from '@/shared/types';

import Vue from 'vue';

import ContractsUploader from '@/components/Basic/Uploader/ContractsUploader.vue';
import { ContractServiceContract, FilesModelContract, UploadServiceContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import ContractItem from './ContractItem.vue';

export default Vue.extend({
  name: 'ContractsCard',

  useInjectable: [Services.Upload, Services.Contract, Models.Files],

  props: {
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: { ContractItem, ContractsUploader },

  data: (): {
    uploadLoading: boolean;
  } => ({
    uploadLoading: false,
  }),

  methods: {
    checkDuplicates(url: string) {
      let hasDuplicates = false;
      this.proposalContracts.forEach((c: { id: string; url: string }) => {
        if (this.fileName(c.url) === this.fileName(url)) {
          hasDuplicates = true;
        }
      });

      if (hasDuplicates) {
        this.$store.dispatch('showSnackbar', { content: 'Contract with same name already uploaded', color: 'warning' });
      }
    },
    async getContractLink(file: File): Promise<string | void> {
      const { isErr, unwrapErr, unwrap } = await (this.uploadService as UploadServiceContract).uploadFile(file, true);

      if (isErr()) {
        const { message } = unwrapErr();

        if (message) this.$store.dispatch('showSnackbar', { content: 'upload error', color: 'error' });
        return;
      }

      const link = unwrap();

      return link;
    },
    fileName(url: string): string {
      return (this[Models.Files] as FilesModelContract).fileName(url);
    },
    async onFilePicked(file: File): Promise<void> {
      this.uploadLoading = true;

      const url = await this.getContractLink(file);

      this.checkDuplicates(url);

      if (!url) {
        this.$store.dispatch('showSnackbar', { content: 'upload error', color: 'error' });
        return;
      }

      const { id, agencyId } = this.$store.state.newProposal.newProposal;

      const input = {
        url,
        proposalId: id,
        agencyId,
      };

      const { isErr, unwrapErr, unwrap } = await (this.contractService as ContractServiceContract).addContract(input);

      if (isErr()) {
        const { message } = unwrapErr();

        if (message) this.$store.dispatch('showSnackbar', { content: 'upload error', color: 'error' });
        return;
      }

      const proposal = unwrap();

      this.$store.dispatch('newProposal/populateProposalData', {
        proposal,
      });
      this.uploadLoading = false;
    },
  },

  computed: {
    loading(): boolean {
      // remove getContractLoading?
      return (
        this.$store.state.newProposal.getContractLoading ||
        this.$store.state.newProposal.addContractLoading ||
        this.uploadLoading
      );
    },
    canAddNewContract(): boolean {
      return !this.isChangeDisabled && this.proposalContracts.length < 12;
    },
    proposalContracts: {
      get(): string[] {
        return [...(this.$store.state.newProposal.newProposal?.contracts || [])];
      },
    },
    generatedContracts(): ProposalContract[] {
      const generatedContracts = this.$store.state.newProposal?.newProposal?.products?.reduce((acc, curr) => {
        return acc.concat(curr?.contract || []);
      }, []);
      return generatedContracts;
    },
  },
});
