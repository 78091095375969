
import Vue from 'vue';

import CreativesCard from '@/components/Proposal/Finalize/CreativesCard.vue';
import ContractsCard from '@/components/Proposal/Finalize/ContractsCard.vue';
import ActionCard from '@/components/Proposal/Finalize/ActionCard.vue';
import { mapActions } from 'vuex';
import { Modules } from '../../store';

export default Vue.extend({
  name: 'ProposalFinalize',

  components: { CreativesCard, ContractsCard, ActionCard },

  props: {
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    hideContracts: boolean;
  } => ({
    hideContracts: false,
  }),

  mounted() {
    this.refetchProposal();
  },

  computed: {
    newProposal() {
      return this.$store.state.newProposal.newProposal;
    },
    isLoading(): boolean {
      return this.$store.state.newProposal.inProgressLoading;
    },
  },

  methods: {
    ...mapActions(Modules.NewProposal, ['setInProgressProposal']),
    async refetchProposal() {
      const { proposalId, id } = this.$route.params;
      await this.setInProgressProposal({
        proposalId,
        PRPID: id,
        forceUpdate: true,
      });
    },
  },

  beforeRouteLeave(to, from, next): void {
    this.$store.dispatch('output/resetOutputAndProposal', {
      routeName: to.name,
    });
    next();
  },
});
