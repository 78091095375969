
import Vue from 'vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Services, Models } from '@/injectables/tokens';
import { ContractServiceContract, FileServiceContract, FilesModelContract } from '@/injectables';

export default Vue.extend({
  name: 'ContractItem',

  useInjectable: [Services.Contract, Models.Files, Services.File],

  props: {
    contract: {
      type: Object,
      required: true,
    },
    uploaded: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: { WrapperWithTooltip },

  methods: {
    fileName(contract: { id: string; url: string }): string {
      const url = contract.url;
      return (this[Models.Files] as FilesModelContract).fileName(url);
    },
    async downloadItem(contract: { id: string; url: string }): Promise<void> {
      const { isErr, unwrap } = await (this.contractService as ContractServiceContract).getContractDownloadUrl(
        contract.id,
      );
      if (isErr()) return;

      (this[Services.File] as FileServiceContract).downloadFileByUrl(unwrap(), this.fileName(contract));
    },
    async removeContract(contract: { id: string; url: string }): Promise<void> {
      const { id, agencyId } = this.$store.state.newProposal.newProposal;
      const { isErr, unwrap } = await (this.contractService as ContractServiceContract).removeContract({
        id: contract.id,
        proposalId: id,
        agencyId,
      });

      if (isErr()) {
        return;
      }

      const proposal = unwrap();

      this.$store.dispatch('newProposal/populateProposalData', {
        proposal,
      });
    },
  },
});
